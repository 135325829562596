import React, { useContext, useRef } from 'react'
import { NavFocusContext } from './Layout'
import { useSwiperSlide } from 'swiper/react'
import { useFullscreenVideo } from '../utils/useFullscreenVideo'
import BackgroundVideo from './BackgroundVideo'

const CarouselVideo = ({
	desktopSource,
	mobileSource,
	fullSource,
	title,
	text,
	director,
	id,
	className,
	captionClassName,
}) => {
	const isNavFocused = useContext(NavFocusContext)
	const swiperSlide = useSwiperSlide()
	const isSelected = swiperSlide?.isActive
	const figureEl = useRef(null)

	const { showFullscreenVideo, playing: fullscreenPlaying } =
		useFullscreenVideo()
	return (
		<figure
			ref={figureEl}
			className={className}
			onClick={(event) => {
				showFullscreenVideo(fullSource)
			}}
			onKeyPress={({ code }) =>
				code === 'Enter' && showFullscreenVideo(fullSource)
			}
		>
			<BackgroundVideo
				key={id}
				desktopSource={desktopSource}
				mobileSource={mobileSource}
				playing={isSelected}
			/>
			<figcaption
				className={`${captionClassName} ${isNavFocused ? 'hidden' : ''}`}
			>
				<span className="mb-4 text-3xl uppercase">
					{director && director.join(' & ')}
				</span>
				<span className="mr-4 font-normal uppercase">{title}</span>
				<span className="uppercase italic">{text}</span>
			</figcaption>
		</figure>
	)
}

export default CarouselVideo
