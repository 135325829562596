// Navbar.js
import React, { useRef } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { HTMLContent } from './Content'
import { useClickOutside } from '../utils/hooks'

const Navbar = ({
	location,
	showMenu,
	setShowMenu,
	showSubMenu,
	setShowSubMenu,
	showContactInfo,
	setShowContactInfo,
}) => {
	const navbarQuery = useStaticQuery(graphql`
		query NavbarQuery {
			directors: allMarkdownRemark(
				filter: { frontmatter: { templateKey: { eq: "director-page" } } }
			) {
				edges {
					node {
						frontmatter {
							name
							newTalent
						}
						id
						fields {
							slug
						}
					}
				}
			}
			contact: allMarkdownRemark(
				filter: { frontmatter: { title: { eq: "Contact" } } }
			) {
				edges {
					node {
						html
					}
				}
			}
		}
	`)

	const directors = navbarQuery.directors.edges
	const oldTalents = directors.filter(
		({
			node: {
				frontmatter: { newTalent },
			},
		}) => !newTalent
	)
	const newTalents = directors.filter(
		({
			node: {
				frontmatter: { newTalent },
			},
		}) => newTalent
	)

	const currentDirector = decodeURIComponent(location.pathname)
	const contactHtml = navbarQuery.contact.edges[0].node.html

	// Fecha um modal quando o usuário clica fora da área ou tecla ESC
	const contactsEl = useRef(null)
	const directorsEl = useRef(null)

	const closeContactModal = () => {
		setShowContactInfo(false)
	}

	const closeDirectorMenu = () => {
		if (showSubMenu) {
			setShowSubMenu(false)
		}
	}

	useClickOutside(contactsEl, closeContactModal, showContactInfo)
	useClickOutside(directorsEl, closeDirectorMenu, showSubMenu)

	return (
		<nav
			className={`
				z-20 col-start-1 col-end-4
				row-start-1
				grid
				grid-cols-2 grid-rows-mobile-nav items-center
				gap-2 px-8
				pt-6
				text-2xl text-white
				md:col-end-5 lg:grid-cols-3 lg:px-20
				${showMenu ? 'h-screen w-screen bg-gray-800 bg-opacity-70' : ''}
				transition-colors
				`}
		>
			<div
				ref={directorsEl}
				className={`
					col-span-3 mt-4
					justify-center self-start justify-self-center lg:col-span-1
					lg:col-start-1 lg:mt-0 lg:block lg:self-center lg:justify-self-start
					${!showMenu ? 'hidden' : 'flex flex-col'}`}
			>
				<button
					className={`cursor-pointer text-base font-light tracking-wide transition-colors hover:font-normal lg:pt-2 lg:text-2xl ${
						showContactInfo ? 'opacity-60' : ''
					}`}
					onClick={() => {
						setShowContactInfo(false)
						setShowSubMenu(!showSubMenu)
					}}
				>
					DIRECTORS
				</button>
				{directors && (
					<ul
						className={` 
							row-start-2 flex min-w-max flex-col
							justify-center font-light
							uppercase tracking-wide
							lg:absolute lg:row-start-1
							lg:ml-0 lg:justify-start
							lg:pt-8 lg:text-6xl
							${!showSubMenu && 'hidden'}
							`}
					>
						{oldTalents.map(
							({
								node: {
									frontmatter: director,
									id,
									fields: { slug },
								},
							}) => (
								<li
									key={id}
									className={`
								flex items-end justify-center hover:font-normal lg:justify-start
								${slug === currentDirector && 'order-first'}
							`}
								>
									<Link
										to={`${slug}`}
										className="director-link group inline-block align-baseline"
									>
										{director.name}
									</Link>
								</li>
							)
						)}
						{newTalents.length > 0 && (
							<span className="mt-4 text-center text-lg  lg:text-left">
								New talents
							</span>
						)}
						{newTalents.map(
							({
								node: {
									frontmatter: director,
									id,
									fields: { slug },
								},
							}) => (
								<li
									key={id}
									className={`
								flex items-end justify-center hover:font-semibold lg:justify-start
							`}
								>
									<Link
										to={`${slug}`}
										className="director-link group inline-block align-baseline"
									>
										{director.name}
									</Link>
								</li>
							)
						)}
					</ul>
				)}
			</div>
			<Link
				to="/"
				className={`lg:w-50 col-span-3 col-start-1 row-start-1 mt-4 self-start justify-self-center lg:col-span-1 lg:col-start-2 ${
					showMenu ? 'opacity-60' : ''
				}`}
			>
				<span className="uppercase tracking-wider lg:text-5xl">Central</span>
			</Link>
			<button
				className="z-10 col-start-3 row-start-1 mt-1 flex max-w-max flex-col items-center gap-2 self-center justify-self-end text-4xl font-light lg:hidden"
				onClick={() => setShowMenu(!showMenu)}
			>
				<hr className="w-5 " />
				<hr className="w-5 " />
			</button>

			<div
				ref={contactsEl}
				className={`
					col-span-3 row-start-3 flex-col self-start justify-self-center
					text-center lg:col-span-1 lg:col-start-3 lg:row-start-1 lg:flex lg:self-center lg:justify-self-end
					${!showMenu ? 'hidden' : 'block'}`}
			>
				<button
					className={` 
						z-50 cursor-pointer
						text-base font-light tracking-wide
						transition-colors hover:font-normal lg:pt-2 lg:text-2xl
						${showSubMenu ? 'opacity-60' : ''}
					`}
					onClick={() => {
						setShowContactInfo(!showContactInfo)
						setShowSubMenu(false)
					}}
				>
					CONTACT
				</button>
				<HTMLContent
					className={`
							mt-4 text-center
							text-lg font-light
							lg:fixed lg:right-20
							lg:ml-0
							lg:mt-12 lg:w-1/4
							lg:flex-col lg:justify-between
							lg:bg-gray-800 lg:bg-opacity-70
							lg:p-8 lg:text-left
							lg:text-2xl
							${!showContactInfo ? 'hidden' : 'lg:flex'}
						`}
					content={contactHtml}
				/>
			</div>
		</nav>
	)
}

export default Navbar
