// Layout.js
import React, { useState, createContext } from 'react'
import { Helmet } from 'react-helmet'
import favicon from '../../static/img/favicon.svg'
import Navbar from './Navbar'
import './all.css'

export const NavFocusContext = createContext(true)

const Layout = ({ children, location }) => {
	// const { title, description } = useSiteMetadata()
	const [showMenu, setShowMenu] = useState(false)
	const [showSubMenu, setShowSubMenu] = useState(false)
	const [showContactInfo, setShowContactInfo] = useState(false)

	return (
		<NavFocusContext.Provider
			value={showSubMenu || showContactInfo || showMenu}
		>
			<div className="grid h-screen min-h-screen grid-cols-1 grid-rows-main bg-black md:grid-cols-4">
				<Helmet>
					<html lang="en" />
					<title>Central Content</title>
					<meta
						name="description"
						content="Producing creative experiences for advertising, entertainment and new media."
					/>
					<link rel="icon" type="image/png" href={favicon} sizes="16x16" />
					{/*Prefetch para otimização da conexao com Vimeo*/}
					<link rel="preconnect" href="https://i.vimeocdn.com" />
					<link rel="dns-prefetch" href="https://i.vimeocdn.com" />
					<link rel="preconnect" href="https://player.vimeo.com" />
					<link rel="dns-prefetch" href="https://player.vimeo.com" />
					<link rel="preconnect" href="https://f.vimeocdn.com" />
					<link rel="dns-prefetch" href="https://f.vimeocdn.com" />
				</Helmet>
				<Navbar
					location={location}
					showMenu={showMenu}
					setShowMenu={setShowMenu}
					showSubMenu={showSubMenu}
					setShowSubMenu={setShowSubMenu}
					showContactInfo={showContactInfo}
					setShowContactInfo={setShowContactInfo}
				/>
				{children}
			</div>
		</NavFocusContext.Provider>
	)
}

export default Layout
