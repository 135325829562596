import React from 'react'
import { useContext } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { createContext } from 'react'
import screenfull from 'screenfull'

const FullscreenVideoContext = createContext(null)

export const FullscreenVideoProvider = ({ children }) => {
	const [src, setSrc] = useState(null)

	const fullscreenVideoRef = useRef()
	const requestFullscreen = (element) => {
		if (element.current.webkitEnterFullscreen) {
			element.current.webkitEnterFullscreen()
			return
		}
		if (src && screenfull.isEnabled) {
			screenfull.request(element.current)
			element.current.play()
			console.log(element)
		}
	}

	useEffect(() => {
		if (typeof document !== 'undefined' && screenfull.isEnabled) {
			screenfull.on('change', (event) => {
				if (!screenfull.isFullscreen) {
					setSrc(null)
				}
			})
		}
	}, [])

	useEffect(() => {
		if (src) {
			requestFullscreen(fullscreenVideoRef)
		}
	}, [src])

	const showFullscreenVideo = (url) => {
		setSrc(url)
	}

	return (
		<FullscreenVideoContext.Provider
			value={{
				showFullscreenVideo,
				playing: src,
			}}
		>
			{src && (
				<video
					ref={fullscreenVideoRef}
					src={src}
					className="z-0 h-0 w-0"
					controls
				/>
			)}
			{children}
		</FullscreenVideoContext.Provider>
	)
}

export const useFullscreenVideo = () => {
	const context = useContext(FullscreenVideoContext)

	if (!context) {
		throw new Error(
			'useFullscreenVideo must be used within an FullscreenVideoProvider'
		)
	}

	return context
}
