import { useState, useEffect } from 'react'

// Função para ajustes responsivos no JS
export function useWindowDimensions() {
	const hasWindow = typeof window !== 'undefined'
	const [windowDimensions, setWindowDimensions] = useState('')

	useEffect(() => {
		function getWindowDimensions() {
			const width = hasWindow ? window.innerWidth : null
			const height = hasWindow ? window.innerHeight : null
			return {
				width,
				height,
			}
		}

		if (hasWindow) {
			setWindowDimensions(getWindowDimensions())

			function handleResize() {
				setWindowDimensions(getWindowDimensions())
			}

			window.addEventListener('resize', handleResize)

			return () => window.removeEventListener('resize', handleResize)
		}
	}, [hasWindow])

	return windowDimensions
}

export function useClickOutside(ref, actionPayload, isVisible) {
	// Fecha um modal quando está aberto e ocorre um clique fora dá área
	useEffect(() => {
		function handleClickOutside(event) {
			if (!isVisible) return

			if (ref.current && !ref.current.contains(event.target)) {
				actionPayload()
			}
		}

		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [ref, isVisible, actionPayload])

	// Fecha um modal quando está aberto e o usuário tecla ESC
	useEffect(() => {
		const closeWithESC = (e) => {
			if (isVisible && e.keyCode === 27) {
				actionPayload()
			}
		}
		window.addEventListener('keydown', closeWithESC)
		return () => window.removeEventListener('keydown', closeWithESC)
	}, [ref, isVisible, actionPayload])
}
