import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

export const HTMLContent = ({ content, className }) => (
	<div className={className}>
		<div className="space-y-6" dangerouslySetInnerHTML={{ __html: content }} />
		<div className="mt-8 flex justify-center lg:justify-start lg:pb-8 gap-2">
			<a
				href="https://www.vimeo.com/centralcontent/"
				target="_blank"
				rel="noreferrer"
			>
				<StaticImage
					className="w-12"
					alt="Central Content @ Vimeo"
					src="../../static/img/vimeo.png"
				/>
			</a>
			<a
				href="https://www.instagram.com/central.content/"
				target="_blank"
				rel="noreferrer"
			>
				<StaticImage
					className="w-12"
					alt="Central Content @ Instagram"
					src="../../static/img/instagram.png"
				/>
			</a>
		</div>
	</div>
)

const Content = ({ content, className }) => (
	<div className={className}>{content}</div>
)

Content.propTypes = {
	content: PropTypes.node,
	className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
