import React from 'react'
import { useEffect } from 'react'
import ReactPlayer from 'react-player'
import { handleSource } from '../utils/video'
import { useWindowDimensions } from '../utils/hooks'

const BackgroundVideo = ({ desktopSource, mobileSource, playing }) => {
	const screenSize = useWindowDimensions()
	return (
		<ReactPlayer
			className={`video-player video-cover`}
			url={handleSource(desktopSource, mobileSource, screenSize)?.source}
			volume={0.5}
			muted
			loop
			playsinline
			wrapper={'div'}
			width="100%"
			height="100%"
			pip={false}
			playing={playing}
			controls={false}
			config={{
				file: {
					hlsOptions: {
						startLevel: 4,
						capLevelToPlayerSize: true,
					},
					attributes: { muted: true },
				},
			}}
		/>
	)
}

export default BackgroundVideo
